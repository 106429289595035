import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Link from 'gatsby-link';
import classNames from 'classnames';

import styles from './styles.module.css';

const Header = ({ showLogo = true, className, title, ...props }) => {
  const links = [
    {
      to: '/cases',
      text: 'Cases',
    },
    {
      to: '/products',
      text: 'Products',
    },
    {
      to: '/about-us',
      text: 'About us',
    },
    {
      to: '/the-team',
      text: 'The team',
    },
    {
      to: '/careers',
      text: 'Careers',
    },
  ];

  return (
    <header
      id="header"
      className={classNames(className, styles.container)}
      {...props}
    >
      <div className={styles.inner}>
        <div
          className={classNames(styles.top, { [styles.hideLogo]: !showLogo })}
        >
          {showLogo ? (
            <div className={styles.left}>
              <Link className={styles.link} to="/">
                <img
                  className={styles.logo}
                  src="/images/north_link_logotyp_long_black.svg"
                  alt={title}
                />
              </Link>
            </div>
          ) : (
            <div className={styles.left}>
              <Link className={styles.link} to="/">
                <img
                  className={styles.logo}
                  src="/images/north_link_mark.svg"
                  alt={title}
                />
              </Link>
            </div>
          )}
          <nav
            className={classNames(styles.right, { [styles.black]: showLogo })}
          >
            <div className={styles.links}>
              {links.map(({ to, text }) => (
                <Link key={to} className={styles.desktop} to={to}>
                  {text}
                </Link>
              ))}
              <Link to="/#location" className={styles.hire}>
                Contact us
              </Link>
            </div>
          </nav>
        </div>
        <nav className={classNames(styles.mobile, styles.bottom)}>
          <div className={styles.mobileLinks}>
            {links.map(({ to, text }) => (
              <Link key={to} to={to}>
                {text}
              </Link>
            ))}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
