import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import LazyLoad from 'react-lazyload';
import Link from 'gatsby-link';

import styles from './styles.module.css';

const Footer = ({ title, products, cases, ...props }) => (
  <footer className={styles.container} {...props}>
    <div className={styles.line} />
    <div className={styles.inner}>
      <div className={styles.content}>
        <div className={styles.column}>
          <div className={styles.logo}>
            <LazyLoad height="4rem">
              <img
                height="4rem"
                width="15rem"
                src="/images/north_link_logotyp_long_black.svg"
                alt={title}
              />
            </LazyLoad>
          </div>
          <div className={styles.address}>
            <span>Sundstorget 3</span>
            <span>252 21 Helsingborg</span>
            <span>+46 70 823 80 35</span>
          </div>
        </div>
        <nav className={styles.footerNav}>
          <div className={styles.column}>
            <p className={styles.columnHeader}>Cases</p>
            <ul>
              {cases.map((caseData, i) => (
                <li key={i}>
                  <Link to={`/cases/${caseData.url}`}>
                    {caseData.shortTitle}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.column}>
            <p className={styles.columnHeader}>Products</p>
            <ul>
              {products.map((product, i) => (
                <li key={i}>
                  <Link to={`/products/${product.url}`}>
                    {product.shortTitle}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.column}>
            <p className={styles.columnHeader}>Follow us</p>
            <ul>
              <li>
                <a
                  href="https://se.linkedin.com/company/north-link-ab"
                  target="_blank"
                >
                  LinkedIn
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <p className={styles.columnHeader}>Company</p>
            <ul>
              <li>
                <Link to="/about-us">About us</Link>
              </li>
              <li>
                <Link to="/careers">Careers</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </footer>
);

export default Footer;
