import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import LazyLoad from 'react-lazyload';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

import styles from './styles.module.css';

const Location = ({ title, ...props }) => (
  <div className={styles.container} {...props}>
    <div className={styles.inner}>
      <div className={styles.locationHeader}>
        <div className={styles.swing}>Swing by for a coffee!</div>
        <div className={styles.hq}>{title}, Helsingborg</div>
      </div>
      <div className={styles.contact}>
        <div className={styles.phone}>
          <div className={styles.icon}>
            <svg viewBox="0 0 24 24">
              <use xlinkHref="/images/phone.svg#path" />
            </svg>
          </div>
          <div>+46 70 823 80 35</div>
        </div>
        <div className={styles.email}>
          <div className={styles.icon}>
            <svg viewBox="0 0 24 24">
              <use xlinkHref="/images/mail_outline.svg#path" />
            </svg>
          </div>
          <div>niklas.silfverstrom@&#8203;northlink.io</div>
        </div>
        <div className={styles.address}>
          <div className={styles.icon}>
            <svg viewBox="0 0 24 24">
              <use xlinkHref="/images/location_city.svg#path" />
            </svg>
          </div>
          <div>
            <p>Sundstorget 3, 252 21 Helsingborg, Sweden</p>
          </div>
        </div>
      </div>
    </div>
    <LazyLoad>
      <MapWithMarker
        mapElement={<div className={styles.map} />}
        containerElement={<div className={styles.mapContainer} />}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCsIsCJyYZ2MQj1QOFFNA-Ocbg-LTv061M"
        loadingElement={<div />}
      />
    </LazyLoad>
  </div>
);

const pos = { lat: 56.048483, lng: 12.689342 };
const MapWithMarker = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap defaultZoom={18} defaultCenter={pos}>
      <Marker icon="/images/location_on.svg" position={pos} />
    </GoogleMap>
  )),
);

export default Location;
